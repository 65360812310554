.contact {
    height: calc(100%);
    overflow: hidden;
    h1 {
        font-weight: 900;
        font-size: 1.6em;
        margin: 20px 0;
        text-transform: uppercase;
        color: white;
    }
}

.formContainer {
    height: calc(100vh - 360px);
    overflow-y: auto;
}

form {
    margin: 10px auto 0 auto;
    max-width: 300px;
    input, textarea {
        display: block;
        border: none;
        padding: 15px;
        font-size: 1em;
        margin: 0 0 15px 0;
        resize: none;
        font-family: inherit;
        outline: none;
        width: 100%;
        margin-right: 20px;
        &:required {
            box-shadow: none;
        }
    }
    button {
        display: block;
        border: none;
        padding: 15px;
        background: #71b373;
        color: white;
        font-size: 1.3em;
        font-weight: bold;
        border-radius: 3px;
        width: 100%;
        transition: all 200ms ease;
        &:hover {
            background: darken($color: #71b373, $amount: 10);
            transform: scale(1.025);
        }
        &:active {
            transform: scale(1);
        }
        &.sent {
            background: #68a2ce;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .formContainer {
        height: calc(100vh - 270px);
    }
}