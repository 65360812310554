.overlayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 80px;
    z-index: 9998;
    height: calc(100vh - 180px);
    width: 100vw;
    background: rgba($color: #000000, $alpha: 0.5);
    opacity: 0;
    transition: opacity 500ms ease;
    &.active {
        opacity: 1;
    }
}

@media only screen and (max-width: 1024px) {
    .overlayContainer {
        height: calc(100vh - 160px);
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    .overlayContainer {
        height: calc(100vh - 190px);
    }
}
