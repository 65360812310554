.pageWork {
    position: relative;
    background: transparent;
    text-align: left;
    overflow: hidden;
    color: white;
    .nav {
        margin: 0;
        padding: 0;
        font-size: 1em;
        width: 100%;
        font-weight: bold;
        border-bottom: 1px solid white;
        .react-tabs__tab {
            text-transform: uppercase;
            font-weight: 300;
            border: none;
            border-radius: 3px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            transition: all 200ms ease;
            border-bottom: 1px solid white;
            background: #787878;
            margin-right: 10px;
            color: white;
            &:hover {
                background: lighten($color: #787878, $amount: 5);
            }
        }
        .react-tabs__tab--selected {
            background: #71b373;
            &:hover {
                background: #71b373;
            }
        }
    }
    .workItem {
        margin-bottom: 20px;
    }
    .workHeader {
        h1 {
            font-weight: 900;
            font-size: 1.6em;
            margin: 20px 0;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .react-tabs__tab-panel--selected {
        animation: fade-and-slide-in 200ms ease;
    }

    .tabContainer {
        margin-top: 20px;
        .tab {
            overflow-y: auto;
            ::-webkit-scrollbar * {
                background:transparent; // manage scrollbar background color here
            }
            .workHeader {
                background: #383838;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                .wiNav {
                    .wiNavBtn {
                        width: 20px;
                        height: 20px;
                    }
                    .visit{
                        color: #68a2ce;
                        margin-right: 10px;
                    }
                    .more {
                        color: #71b373;
                    }
                }
            }
            h2 {
                display: inline-block;
                font-weight: bold;
                font-size: 1.1em;
                text-transform: uppercase;
                margin: 0;
            }
            h3 {
                text-transform: lowercase;
                font-size: 1em;
                margin: 0;
                font-style: italic;
                font-weight: 400;
                padding-top: 4px;
            }
            p {
                padding: 70px;
                font-size: .9em;
                line-height: 26px;
                background: #585858;
                margin: 0;
            }
        }
        .react-tabs__tab-panel--selected {
            height: calc(100vh - 420px);
        }
    }
}

@media only screen and (max-width: 1024px) {
    .pageWork {
        .nav {
            font-size: .8em;
        }
        .tabContainer {
            .tab {
                .workHeader {
                    text-align: center;
                    flex-direction: column;
                    .wiNavBtn {
                        margin-top: 10px;
                    }
                }
                p {
                    padding: 40px;
                }
                h3 {
                    margin-bottom: 10px;
                }
            }
            .react-tabs__tab-panel--selected {
                height: calc(100vh - 320px);
            }
        }
    }
}

