.animationContainer {
    position: fixed;
    left: 0;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 160px);
    overflow: hidden;
    padding-bottom: 60px;
    .road {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 90;
        width: 13500px;
        height: 200px;
        background-image: url(../../images/animation/landscape/road.svg);
        background-repeat: repeat-x;
        will-change: transform;
        animation: road 8s linear infinite;
    }
    .bike {
        position: absolute;
        bottom: 150px;
        left: 20px;
        z-index: 100;
        width: 120px;
        .rider, .tiger, .bikeFront, .bikeRear {
            position: absolute;
        }
        .tiger {
            left: 0;
        }
        .rider {
            width: 50px;
            left: 0;
            bottom: 0;
            .riderBody, .riderHead {
                position: absolute;
            }
            .riderBody {
                width: 50px;
                left: 45px;
                bottom: -68px;
            }
            .riderHead {
                width: 20px;
                left: 65px;
                bottom: -5px;
            }
        }
        .bikeFront {
            width: 35px;
            left: 0;
            top: 0;
            animation: fSuspension 4s ease infinite;
            .fork, .fWheel {
                position: absolute;
            }
            .fork {
                width: 15px;
                left: 100px;
                bottom: -60px;
            }
            .fWheel {
                width: 35px;
                left: 95px;
                bottom: -76px;
                will-change: transform;
                animation: spin 200ms linear infinite;
            }
        }
        .bikeRear {
            width: 40px;
            left: 0;
            top: 0;
            animation: rSuspension 4s ease infinite;
            .swingarm, .rWheel {
                position: absolute;
            }
            .swingarm {
                width: 40px;
                left: 25px;
                bottom: -61px;
            }
            .rWheel {
                width: 35px;
                left: 10px;
                bottom: -76px; 
                will-change: transform;
                animation: spin 200ms linear infinite;
            }
        }
    }
    .cityScape {
        position: absolute;
        bottom: 212px;
        left: 0;
        z-index: 40;
        width: 13500px;
        .cityDay {
            // background-image: url(../../images/animation/landscape/city-day.svg);
            background-repeat: repeat-x;
            height: 500px;
            width: 13500px;
            will-change: transform;
            animation: city 50s linear infinite;
        }
    }
    .bgLandscape {
        position: absolute;
        left: 0;
        bottom: 210px;
        z-index: 49;
        background-image: url(../../images/animation/landscape/bglandscape.svg);
        height: 400px;
        width: 13500px;
        background-repeat: repeat-x;
        background-size: 33.33%;
        background-repeat: repeat-x;
        background-position: bottom;
        will-change: transform;
        animation: city 120s linear infinite;
    }
    .buildings {
        position: absolute;
        left: 0;
        bottom: 214px;
        z-index: 49;
        background-image: url(../../images/animation/landscape/buildings.svg);
        height: 400px;
        width: 13500px;
        background-repeat: repeat-x;
        background-size: 33.33%;
        background-repeat: repeat-x;
        background-position: bottom;
        will-change: transform;
        animation: city 35s linear infinite;
    }
    .buildings2 {
        position: absolute;
        left: 0;
        bottom: 215px;
        z-index: 49;
        background-image: url(../../images/animation/landscape/buildings2.svg);
        height: 400px;
        width: 13500px;
        background-repeat: repeat-x;
        background-size: 33.33%;
        background-repeat: repeat-x;
        background-position: bottom;
        will-change: transform;
        animation: city 50s linear infinite;
    }
    .trees2 {
        position: absolute;
        left: 0;
        bottom: 188px;
        z-index: 49;
        background-image: url(../../images/animation/landscape/trees2.svg);
        height: 170px;
        width: 4500px;
        background-repeat: repeat-x;
        background-size: 33.33%;
        background-position: left center;
        background-repeat: repeat-x;
        will-change: transform;
        animation: trees 25s linear infinite;
    }
    .trees1 {
        position: absolute;
        left: 0;
        bottom: 177px;
        z-index: 49;
        background-image: url(../../images/animation/landscape/trees1.svg);
        height: 210px;
        width: 4500px;
        background-size: 33.33%;
        background-position: left center;
        background-repeat: repeat-x;
        will-change: transform;
        animation: trees 20s linear infinite;
    }
    .sidewalk {
        position: absolute;
        left: 0;
        bottom: 120px;
        z-index: 50;
        width: 13500px;
        height: 100px;
        background: gray;
        will-change: transform;
        animation: sidewalk 30s linear infinite;
        .bb1, .bb2, .bb3, .bb4 {
            position: absolute;
            bottom: 90px;
        }
        .bb1 {
            width: 350px;
            left: 2000px;
        }
        .bb2 {
            width: 100px;
            left: 4000px;
        }
        .bb3 {
            width: 100px;
            left: 7000px;
        }
        .bb4 {
            width: 400px;
            left: 9000px;
        }
    }
}

@keyframes road {
    0%{
      transform: translate3d(0, 0, 0);
    }
    100%{
      transform: translate3d(-4500px, 0, 0); /* The image width */
    }
}

@keyframes city {
    0%{
      transform: translate3d(0, 0, 0);
    }
    100%{
      transform: translate3d(-33.33%, 0, 0); /* The image width */
    }
}

@keyframes trees {
    0%{
      transform: translate3d(0, 0, 0) scale(2);
      
    }
    100%{
      transform: translate3d(-66.66%, 0, 0) scale(2); /* The image width */
    }
}

@keyframes sidewalk {
    0%{
      transform: translate3d(0, 0, 0);
    }
    100%{
      transform: translate3d(-80%, 0, 0); /* The image width */
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rSuspension {
    0% {
        transform: translate3d(0, 0, 0);
    }
    10% {
        transform: translate3d(0, -0.25px, 0);
    }
    20% {
        transform: translate3d(0, 0, 0);
    }
    30% {
        transform: translate3d(0, -0.5px, 0);
    }
    40% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 0, 0);
    }
    60% {
        transform: translate3d(0, -1px, 0);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    80% {
        transform: translate3d(0, 0, 0);
    }
    90% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -0.25px, 0);
    }
}

@keyframes fSuspension {
    0% {
        transform: translate3d(0, 0, 0);
    }
    10% {
        transform: translate3d(-0.25px, -0.25px, 0);
    }
    20% {
        transform: translate3d(0, 0, 0);
    }
    30% {
        transform: translate3d(-0.5px, -0.5px, 0);
    }
    40% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 0, 0);
    }
    60% {
        transform: translate3d(-0.25px, -0.25px, 0);
    }
    70% {
        transform: translate3d(0, 0, 0);
    }
    80% {
        transform: translate3d(0, 0, 0);
    }
    90% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-0.5px, -0.5px, 0);
    }
}