.overlayMenu {
	overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    height: 100px;
    width: 100vw;
    display: flex;
    align-items: center;
	background: #383838;
	transform-origin: bottom left;
	transition: all 500ms ease;
}

@media only screen and (max-width: 1024px) {
    .overlayMenu {
        justify-content: center;
    }
}