* {
	box-sizing: border-box;
}

@font-face {
	font-family: "Avenir", sans-serif;
	src: url("./fonts/Avenir Next.woff");
}

@font-face {
	font-family: "Circular", sans-serif;
	src: url("./fonts/circular.woff2");
}

$avenir: "Avenir", sans-serif;
$circular: "Circular", sans-serif;

.App {
	text-align: center;
	font-family: $avenir;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $avenir;
}

p {
	font-family: $circular;
}

.overlayMenuContainer {
	overflow: hidden;
}

.overlayToggle {
	shape-outside: polygon(100% 0, 0% 100%, 100% 100%);
	clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	height: 100px;
	width: 100px;
	z-index: 100000;
	background: #71b373;
	position: fixed;
	bottom: 0;
	right: 0;
	overflow: hidden;
	transform-origin: bottom right;
	transition: all 200ms ease;
	cursor: pointer;

	.icon {
		position: absolute;
		bottom: 15px;
		right: 15px;
		font-size: 2em;
		color: white;
		filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
		transition: all 200ms ease;
		user-select: none;
	}

	#infoClose, #infoOpen {
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}

	&:hover {
		#infoOpen, #infoClose {
			transform: scale(1.2) rotate(15deg);
		}
	}

	&.active {
		background: #cb6e6e;
	}
}

.embed-container { 
	position: relative; 
	padding-bottom: 56.25%; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed { 
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%;
}

@media only screen and (max-width: 1024px) {
	.overlayToggle {
		height: 80px;
		width: 80px;
		.icon {
			bottom: 12px;
			right: 12px;
			font-size: 1.5em;
		}
		&.active {
			background: #cb6e6e;
		}
	}
	.overlayMenu {
		height: 80px;
		width: 100vw;
	}
}

@media (hover: none) {
	.overlayToggle {
		&:hover {
			transform: scale(1);
			background: #71b373;
			#infoOpen, #infoClose {
				transform: scale(1) rotate(0);
			}
		}
		&.active {
			background: #cb6e6e;
		}
	}
}

@media only screen and (max-width: 1024px) {
    .animationContainer {
        height: calc(100vh - 160px);
    }
}

@supports (-webkit-overflow-scrolling: touch) {

    /* ----------- iPhone XS Max & XR Portrait ----------- */

    @media only screen 
	and (max-height : 896px) 
	and (-webkit-device-pixel-ratio : 3) 
	and (orientation : portrait) { 
		.animationContainer, .overlayModal {
			height: calc(100vh - 270px);
		}
		.formContainer {
			height: calc(100vh - 400px);
		}
		.pageWork {
			.tabContainer {
				.react-tabs__tab-panel--selected {
					height: calc(100vh - 430px);
				}
			}
		}
	}
	
	 /* ----------- iPhone X, and XS Portrait ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (max-height: 812px) 
    and (-webkit-min-device-pixel-ratio: 3) 
    and (orientation : portrait) { 
        .animationContainer, .overlayModal {
            height: calc(100vh - 230px);
		}
		.formContainer {
			height: calc(100vh - 380px);
		}
		.pageWork {
			.tabContainer {
				.react-tabs__tab-panel--selected {
					height: calc(100vh - 430px);
				}
			}
		}
	}
	
	/* ----------- iPhone 6+, 7+ and 8+ Portrait ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (max-height: 736px) 
    and (-webkit-min-device-pixel-ratio: 3) 
    and (orientation : portrait) { 
        .animationContainer, .overlayModal {
            height: calc(100vh - 230px);
		}
		.formContainer {
			height: calc(100vh - 380px);
		}
		.pageWork {
			.tabContainer {
				.react-tabs__tab-panel--selected {
					height: calc(100vh - 390px);
				}
			}
		}
	}

	/* ----------- iPhone 6, 6S, 7 and 8 Portrait ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (max-height: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) 
    and (orientation : portrait) { 
        .animationContainer, .overlayModal {
            height: calc(100vh - 230px);
		}
		.formContainer {
			height: calc(100vh - 360px);
		}
    }
	
	/* ----------- iPhone 5, 5S, 5C and 5SE Portrait ----------- */

    /* Portrait and Landscape */
    @media only screen 
    and (max-height: 568px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation : portrait) {
        .animationContainer, .overlayModal {
            height: calc(100vh - 230px);
		}
		.formContainer {
			height: calc(100vh - 320px);
		}
		.pageWork {
			.tabContainer {
				.react-tabs__tab-panel--selected {
					height: calc(100vh - 390px);
				}
			}
		}
    }
}