header {
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    background: #383838;
    position: fixed;
    top: 0;
    left: 0;
    .logo {
        width: 70px;
        margin-left: 20px;
    }
    .headerNav {
        margin-left: auto;
        margin-right: 10px;
        .headerNavLink {
            color: white;
            font-size: 1.5em;
            padding: 0 10px;
            &:hover {
                .icon {
                    color: #68a2ce;
            transform: scale(1.3);
                }
            }
            .icon {
                transition: all 200ms ease;
            }
        }
    }
}

@media (hover: none) {
    header {
        .headerNav {
            .headerNavLink {
                &:hover {
                    .icon {
                        color: white;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}