.pageMe {
    overflow-y: auto;
    padding-right: 0;
    @supports (-moz-appearance:none) {
        padding-right: 20px;
    }
}
.bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: white;
    p {
        text-align: left;
        margin-bottom: 0;
        font-size: .9em;
        line-height: 26px;
        max-width: 300px;
    }
    .meHeader {
        display: flex;
        align-items: center;
        margin: 20px 0;
        .photo {
            align-self: center;
            max-width: 100px;
            border-radius: 100%;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .bio {
        padding: 20px;
        .meHeader {
            flex-direction: column;
            h1 {
                font-size: 1.5em;
                margin-bottom: 0;
            }
        }
    }
}

@media (hover: none) {
    .pageMe {
        padding-right: 0px;
        .bio {
            padding: 30px;
        }
    }
}