.dayCycle {
    width: 100%;
    height: 100%;
    .cycleLayer {
        width: 100%;
        height: calc(100vh - 300px);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background-repeat: no-repeat;
        background-size: cover;
        transition: opacity 20000ms linear;
    }
    .cycleLayer.active {
        opacity: 1;
    }
    .sunrise {
        z-index: 10;
        background-image: url(../../../images/animation/timeOfDay/sunrise.svg);
    }
    .day {
        z-index: 11;
        background-image: url(../../../images/animation/timeOfDay/day.svg);
    }
    .sunset {
        z-index: 12;
        background-image: url(../../../images/animation/timeOfDay/sunset.svg);
    }
    .dusk {
        z-index: 13;
        background-image: url(../../../images/animation/timeOfDay/dusk.svg);
    }
    .night {
        z-index: 14;
        background-image: url(../../../images/animation/timeOfDay/night.svg);
    }
}